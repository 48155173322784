import React from 'react'
import { ApiService, IProductPreview, IProductStore, IStoreKind, IStoreProductSuggestion, StoreKind } from '../../services/api.service'
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Toolbar } from 'primereact/toolbar';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';


interface ProductStoresEditProps {
    selectedProduct: IProductPreview,
    selectedCategories: string[],
    onSave: (data: IProductStore[]) => void
}
export const ProductStoresEdit: React.FC<ProductStoresEditProps> = (props) => {
    const service = new ApiService();
    const [stores, setStores] = React.useState<{ kind: StoreKind, selected: IProductStore, suggestions?: any[] }[]>([]);
    const [existings, setExistings] = React.useState<{ kind: StoreKind, productId: string, productName: string }[]>([]);
    const [activeKinds, setActiveKinds] = React.useState<IStoreKind[]>([]);

    React.useEffect(() => {

        const run = async () => {
            setExistings([]);
            let kinds = activeKinds;
            if (kinds.length === 0) {
                kinds = await service.getActiveStoreKinds();
                setActiveKinds([...kinds]);
            }

            setStores(kinds.map(kind => ({
                kind: kind.id as any,
                selected: {
                    kind: kind.id as any,
                    key: props.selectedProduct.stores.find(p => p.kind === kind.id)?.key || '',
                    name: props.selectedProduct.stores.find(p => p.kind === kind.id)?.name || '',
                    image: props.selectedProduct.stores.find(p => p.kind === kind.id)?.image || '',
                    price: props.selectedProduct.stores.find(p => p.kind === kind.id)?.price || 0,
                    weight: props.selectedProduct.stores.find(p => p.kind === kind.id)?.weight,
                    type: props.selectedProduct.stores.find(p => p.kind === kind.id)?.type || 'same',
                }
            })));

        }
        run();


    }, [props])





    const changeValue = (action: () => void) => {
        action();
        setStores([...stores])
    }

    const search = async (item: any, event: any) => {
        const result = await service.getStoreProductsSuggestions(item.kind, event.query)
        console.log({ result })
        changeValue(() => item.suggestions = result.map(m => ({ ...m, type: 'same' })));
    }

    const [saving, setSaving] = React.useState(false);

    const save = async () => {
        setSaving(true);
        const data: IProductStore[] = stores.filter(p => p.selected && p.selected.price > 0 && p.selected.name).map(item => ({
            kind: item.kind,
            name: item.selected.name,
            price: item.selected.price,
            key: item.selected.key,
            type: item.selected.type,
            weight: item.selected.weight,
            image: item.selected.image,

        }));

        console.log("updating => ", {
            productId: props.selectedProduct.id,
            data
        })

        const result = await service.updateProductStores(props.selectedProduct.id, data);
        if (result.success) {
            props.onSave(data);
        }
        else {
            setExistings(result.existings || []);
        }
        setSaving(false);
    }

    const actionButtons = (
        <>
            <Button loading={saving} label="Ürünü Kaydet" icon="pi pi-save" className="ml-12" onClick={save} />
        </>
    );


    const getKindText = (kind: StoreKind) => {
        return kind === StoreKind.a101 ? "A-101" :
            kind === StoreKind.bim ? "BİM" :
                kind === StoreKind.carrefoursa ? "CARREFOURSA" :
                    kind === StoreKind.migros ? "MİGROS" :
                        kind === StoreKind.sok ? "ŞOK" : "<TANIMSIZ>";
    }

    const itemTemplate = (item: IStoreProductSuggestion) => (
        <div className="flex items-center gap-4">
            <img src={item.image} className="w-10 h-10 object-contain" />
            <div className="flex-1">
                <div>{item.name}</div>
                <div className="text-sm text-gray-300">{getKindText(item.kind)}</div>
            </div>
            <span className="font-bold text-lg">{item.price.toFixed(2)} TL</span>
        </div>
    )

    const types = [
        { label: 'Aynı', value: 'same' },
        { label: 'Benzer', value: 'equivalent' },
    ]


    let controls: { kind: string, control: AutoComplete }[] = [];

    React.useEffect(() => {
        return () => {
            console.log("disposing edit compoment")
            controls = [];
        }
    }, [])
    return (
        <Card>


            <ul className="space-y-0">
                <li className="flex items-center px-2 gap-2 font-semibold">
                    <h3 className="w-10"></h3>
                    <h3 className="flex-1">ÜRÜN ADI</h3>
                    <h3 className="w-24 text-center pr-2">G/ML</h3>
                    <h3 className="w-28 text-center pr-2">TİP</h3>
                    <h3 className="w-28 text-right pr-2">FİYAT</h3>

                </li>
                {stores.map(item => (
                    <li key={item.kind} className={`${item.selected.type === 'equivalent' ? 'bg-yellow-100 rounded-md' : 'bg-white'}`}>

                        <div className={`flex items-center p-2 gap-2  ${existings.some(s => s.kind === item.kind) ? '!pb-0' : ''}`}>
                            <div className="w-10">
                                <img src={activeKinds.find(p => p.id === item.kind)?.logo} className="object-contain" />
                            </div>

                            <div className="flex-1">
                                <div>
                                    <AutoComplete
                                        disabled={item.kind === StoreKind.migros}
                                        ref={r => {
                                            if (r) {
                                                controls.push(({ kind: item.kind, control: r }));
                                            }
                                        }}
                                        className="w-full p-inputtext-sm"
                                        inputId={`input_${item.kind}`}
                                        inputClassName="w-full"
                                        scrollHeight="500px"
                                        field="name"
                                        value={item.selected}
                                        suggestions={item.suggestions}
                                        completeMethod={e => search(item, e)}
                                        itemTemplate={itemTemplate}
                                        onChange={(e) => changeValue(() => {
                                            item.selected = e.value;
                                        })} />

                                </div>
                            </div>

                            <div className="w-24">
                                <InputNumber className="w-full p-inputtext-sm" inputClassName="w-full text-right" value={item.selected.weight || 0} onValueChange={(e) => changeValue(() => item.selected.weight = e.value)} />
                            </div>

                            <div className="w-28">
                                <Dropdown disabled={item.kind === StoreKind.migros} className="w-full p-inputtext-sm" value={item.selected.type} options={types} onChange={(e) => changeValue(() => item.selected.type = e.value)} />
                            </div>

                            <div className="w-28">
                                <InputNumber disabled className="w-full p-inputtext-sm" inputClassName="w-full text-right" value={item.selected.price} onValueChange={(e) => changeValue(() => item.selected.price = e.value)} mode="currency" currency="TRY" locale="tr-TR" />
                            </div>
                        </div>
                        {existings.some(s => s.kind === item.kind) && (<small className="ml-14 p-error">Bu ürün daha önce <strong>{existings.find(s => s.kind === item.kind)?.productName}</strong> ürünü için seçilmiştir!</small>)}
                        {item.kind !== StoreKind.migros && props.selectedCategories.length > 0 && (
                            <div className="flex items-center -mt-1 ml-14 space-x-2 mb-2">
                                {props.selectedCategories.filter((_, i) => i > 0).reverse().map((cat, index) => (
                                    <Button
                                        key={index}
                                        label={cat}
                                        className="p-button-raised p-button-text p-button-plain p-button-sm !p-1"
                                        onClick={() => {
                                            const ref = controls.find(p => p.kind === item.kind)?.control as any;
                                            ref.onInputChange({ target: { value: cat } })
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                    </li>
                ))}

            </ul>

            <Toolbar left={actionButtons} />

        </Card>
    )
}
